
.ag-header-viewport{
  background: white;
  text-decoration:none;
}
.ag-center-cols-clipper{
  margin-left:-0.1rem;
}

.ag-floating-filter-input a {
  position: absolute;
  right: 40px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}

.ag-floating-filter-input:after {
  position: absolute;
  content: '\f073';
  display: block;
  font-weight: 400;
  font-family: 'Poppins';
  right: 5px;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
}
.ag-theme-alpine .ag-header-row{
  height:48px;
}
.ag-ltr .ag-body-viewport{
  margin-top:-0.1rem;
  position: relative;
}

.ag-header-cell-text{
  font-size: 0.94rem;
  padding-left: 1rem;
  height:1.4rem;
}
.ag-theme-alpine .ag-header-cell{
  padding-left:0px;
  border-right: 0.1rem solid white;
  padding-right:0px;
  text-decoration:none;
}
.ag-theme-alpine .ag-root-wrapper{
  border:none;
  text-decoration:none;
}
.ag-theme-alpine .ag-header{
  border:none;
  text-decoration:none;
}
.ag-theme-alpine .ag-cell{
  line-height: 36px;
  text-decoration:none;
}
.ag-theme-alpine .ag-ltr .ag-cell{
  border-right: 0.2rem solid white;
  border-radius:0.5rem;
  line-height: 3.6;
  text-decoration:none;
  text-align: left;
}
.ag-theme-alpine .ag-ltr .ag-cell:nth-child(4){
  border-right: 0.2rem solid white;
  border-radius:0.5rem;
  text-align: center;
  text-decoration:none;
}
.ag-theme-alpine .ag-ltr .ag-cell:nth-child(5){
  border-right: 0.2rem solid white;
  border-radius:0.5rem;
  text-align: center;
  text-decoration:none;
  background-color:#e6f3ff;
}
.ag-theme-alpine .ag-ltr .ag-cell:nth-child(6){
  border-right: 0.2rem solid white;
  border-radius:0.5rem;
  text-align: center;
  text-decoration:none;
  background-color:#e6f3ff;
}
.ag-theme-alpine .ag-ltr .ag-cell:nth-child(7){
  border-right: 0.2rem solid white;
  border-radius:0.5rem;
  text-align: center;
  text-decoration:none;
}
.rowGreyBackgroundwithImg{
  background-color: #f6f6f6;
  text-decoration:none;
  background-image: url('../../images/boy.svg');
  background-position: left 8% top 52%;
  background-repeat: no-repeat;
  background-size: 1.2rem;
  text-align:'left';
  text-decoration:none;
}
.rowGreyBackgroundwithAgencyImg{
  background-image: url('../../images/car.svg');
  background-position: left 1rem top 50%;
  background-repeat: no-repeat;
  background-size: 1.2rem;
  background-color: #f6f6f6;
  text-align:'left';
  text-decoration:none;
}
.rowGreyBackground{
  background-color: #f6f6f6;
  text-align:'center';
  text-decoration:none;
  text-align:'center';
  text-decoration:none;
  font-family: Poppins;
}

.rowBlueBackground{
  background-color: #e6f3ff;
  text-align:'center';
  text-decoration:none;
}

.rowGreenBackground{
  background-color:#DFF7E6;
  text-align:'center';
  text-decoration:none;
}
.ag-input-field-input{
  height:2.7rem;
  width:100%;
}
.ag-theme-alpine .ag-ltr input[class^="ag-"][type="text"]{
  border:none;
  color:black;
  text-decoration:none;
  height:40px;
  font-size: 0.96rem;
  z-index: 1000;
  border:1px solid grey;
  padding-left:2.5rem;
  border-radius: 0.5rem;
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: left 1rem bottom 50%;
  background-image: url('../../imagesMB/search.svg');
}
.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell{
  background: none;
  border:none;
  height:43px; 
  font-family: Poppins;
}
.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell:nth-child(3),
.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell:nth-child(4){
  background:#eff0f6;
  border-radius: 0px;
  height:43px;
  font-family: Poppins;
  border-top-left-radius:0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell:nth-child(5),
.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell:nth-child(6),
.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell:nth-child(8),
.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell:nth-child(9),
.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell:nth-child(10){
  background:#eff0f6;
  border-radius: 0px;
}

.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell:nth-child(7){
  background:#eff0f6;
  border-radius: 0px;
  border-top-right-radius:0.2rem ;
  border-bottom-right-radius: 0.2rem;
}

.ag-theme-alpine .ag-header-row .ag-header-cell{
  background:#999999;
  border-radius:0.5rem;
  color:white;
  font-family: Poppins;
}

.ag-theme-alpine .ag-header-row .ag-header-cell:nth-child(5),
.ag-theme-alpine .ag-header-row .ag-header-cell:nth-child(6){
  background:#297fcc;
  border-radius:0.5rem;
  color:white;
}

.ag-theme-alpine .ag-row{
  border-width:0px;
  border-bottom-style: none;
  border: 0.1rem solid white;
  border-top: 0.2rem solid white;
}

.ag-theme-alpine a{
  text-underline-position: under;
  color:black;
  font-size:1rem;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
}

/*--------------------------------------------------------------------------------------------------------------*/
/*                                               GRID EVAL ADMIN                                                      */
.ag-theme-alpine-Admin-Eval .ag-header-viewport{
  background: white;
  text-decoration:none;
}
.ag-theme-alpine-Admin-Eval .ag-theme-alpine .ag-header-row{
  height:60px;
}
.ag-theme-alpine-Admin-Eval .ag-ltr .ag-body-viewport{
  margin-top:-1rem;
}
.ag-theme-alpine-Admin-Eval .ag-header-cell-text{
  font-size: 0.90rem;
  padding-left: 1rem;
  font-family: sans-serif;
  font-weight: 600;
  align-items: center;
  display: flex;
  justify-content: center;
}

.ag-theme-alpine-Admin-Eval .ag-header-cell{
  padding-left:0px;
  border: 1px solid white;
  padding-right:0px;
  text-decoration:none;
  box-sizing: border-box;
}
.ag-theme-alpine-Admin-Eval .ag-root-wrapper{
  border:none;
  text-decoration:none;
}
.ag-theme-alpine-Admin-Eval .ag-header{
  border:none;
  text-decoration:none;
}
.ag-theme-alpine-Admin-Eval .ag-cell{
  line-height: 36px;
  text-decoration:none;
}
.ag-theme-alpine-Admin-Eval .ag-ltr .ag-cell{
  border-radius:0.5rem;
  line-height: 3.6;
  text-decoration:none;
  font-family: sans-serif;
  border: 0.5px solid white;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.ag-theme-alpine-Admin-Eval .ag-ltr .ag-cell:nth-child(1){
  text-decoration:none;
}

.ag-theme-alpine-Admin-Eval .ag-ltr .ag-cell:nth-child(2){
  text-decoration:none;
  padding-left: 0.7rem;
}

.ag-theme-alpine-Admin-Eval .ag-ltr .ag-cell:nth-child(3){
  text-decoration:none;
  text-align: center;
}

.ag-theme-alpine-Admin-Eval .ag-ltr .ag-cell:nth-child(4),
.ag-theme-alpine-Admin-Eval .ag-ltr .ag-cell:nth-child(6),
.ag-theme-alpine-Admin-Eval .ag-ltr .ag-cell:nth-child(7),
.ag-theme-alpine-Admin-Eval .ag-ltr .ag-cell:nth-child(8){
  justify-content: space-around;
}
.ag-theme-alpine-Admin-Eval .ag-ltr .ag-cell:nth-child(5){
  text-align: center;
  text-decoration:none;
  justify-content: center;
}

.ag-theme-alpine-Admin-Eval .rowGreyBackgroundwithImgActive{
  background-color: #f6f6f6;
  text-align:'center';
  text-decoration:none;
  background-image: url('../../images/boy.svg');
  background-position: left 5% top 52%;
  background-repeat: no-repeat;
  background-size: 1.2rem;
  text-align:'left';
  text-decoration:none;
}

.ag-theme-alpine-Admin-Eval .rowGreyBackgroundwithImgInactive::before{
  content: '';
  position: absolute;
  background-image: url('../../images/boy.svg');
  background-position: left 5% top 52%;
  background-repeat: no-repeat;
  background-size: 1.2rem;
  width: 100%;
  height: 100%;
  filter: invert(50%);
}

.ag-theme-alpine-Admin-Eval .rowGreyBackgroundwithImgInactive{
  background-color: #f6f6f6;
  text-align:'center';
  text-decoration:none;
  text-align:'left';
  text-decoration:none;
}

.ag-theme-alpine-Admin-Eval .rowGreyBackgroundwithAgencyImgActive{
  background-image: url('../../images/car.svg');
  background-position: left 5% top 50%;
  background-repeat: no-repeat;
  background-size: 1.2rem;
  background-color: #f6f6f6;
  text-align:'left';
  text-decoration:none;
}

.ag-theme-alpine-Admin-Eval .rowGreyBackgroundwithAgencyImgInactive::before{
  content: '';
  position: absolute;
  background-image: url('../../images/car.svg');
  background-position: left 5% top 50%;
  background-repeat: no-repeat;
  background-size: 1.2rem;
  width: 100%;
  height: 100%;
  filter: invert(50%);
}

.ag-theme-alpine-Admin-Eval .rowGreyBackgroundwithAgencyImgInactive{
  background-color: #f6f6f6;
  text-align:'left';
  text-decoration:none;
}

.ag-theme-alpine-Admin-Eval .rowGreyBackground{
  background-color: #f6f6f6;
  text-align:'center';
  text-decoration:none;

}
.ag-theme-alpine-Admin-Eval .rowBlueBackground{
  background-color: #e6f3ff;
  text-align:'center';
  text-decoration:none;
}
.ag-theme-alpine-Admin-Eval .rowGreenBackground{
  background-color:#DFF7E6;
  text-align:'center';
  text-decoration:none;
}

.ag-theme-alpine-Admin-Eval .ag-ltr input[class^="ag-"][type="text"]{
  color:black;
  text-decoration: none;
  height: 30px;
  font-size: 14px;
  z-index: 1000;
  border: 1px solid hsl(0, 0%, 80%);
  padding: 0px 0px 0px 2.5rem;
  border-radius: 4px;
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: left 1rem bottom 50%;
  background-image: url('../../imagesMB/search.svg');
  margin: 0px 1px 0px 1px;
  cursor: pointer;
}

.ag-theme-alpine-Admin-Eval .ag-ltr input[class^="ag-"][type="text"]:focus-visible {
  outline-color: #2684FF;
}

.ag-theme-alpine-Admin-Eval .ag-ltr input[class^="ag-"][type="text"]::placeholder {
  color: #808080;
}

.ag-theme-alpine-Admin-Eval .ag-header-row:not(:first-child) .ag-header-cell{
  background:#eff0f6;
  border:none;
  height:43px;
  border-radius: 0px;
}
.ag-theme-alpine-Admin-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(1){
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ag-theme-alpine-Admin-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(5),
.ag-theme-alpine-Admin-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(6),
.ag-theme-alpine-Admin-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(7){
  background:#eff0f6;
}

.ag-theme-alpine-Admin-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(8)
{
  background:#eff0f6;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.ag-theme-alpine-Admin-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(9),
.ag-theme-alpine-Admin-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(10)
{
  background:#eff0f6;
}

.ag-theme-alpine-Admin-Eval .ag-header-row .ag-header-cell{
  background:#999999;
  border-radius:5px;
  color:white;
  border:1px solid white;
}
.ag-theme-alpine-Admin-Eval .ag-header-row .ag-header-cell:nth-child(5),
.ag-theme-alpine-Admin-Eval .ag-header-row .ag-header-cell:nth-child(6){
  background:#53AD49;
  color:white;
}

.ag-theme-alpine-Admin-Eval .ag-header-row .ag-header-cell:nth-child(7){
  background:#999999;
  color:white;
}

.ag-theme-alpine-Admin-Eval .ag-header-row .ag-header-cell:nth-child(8),
.ag-theme-alpine-Admin-Eval .ag-header-row .ag-header-cell:nth-child(9){
  background:#297fcc;
  color:white;
}

.ag-theme-alpine-Admin-Eval .ag-row{
  border-width:0px;
  border-bottom-style: none;
  border: 0.1rem solid white;
  border-top: 0.2rem solid white;
}


.ag-theme-alpine-Admin-Eval a{
  text-decoration: none;
  color:black;
  font-size:1rem;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
}

.ag-theme-alpine-Admin-Eval .ag-ltr .ag-body-viewport{
  margin-top:-0.1rem;
}

/*-----------------------------------------------------------------------------------------*/
/*                                     GRID EVAL                                           */

.ag-theme-alpine-Eval{
  justify-content: center;
}

.ag-theme-alpine-Eval .ag-header-viewport{
  background: white;
  text-decoration:none;
}

.ag-theme-alpine-Eval .ag-theme-alpine .ag-header-row{
  height:60px;
}
.ag-theme-alpine-Eval .ag-ltr .ag-body-viewport{
  margin-top:-1rem;
}
.ag-theme-alpine-Eval .ag-header-cell-text{
  font-size: 0.90rem;
  padding-left: 1rem;
  font-family: sans-serif;
  font-weight: 600;
  align-items: center;
  display: flex;
  justify-content: center;
}

.ag-theme-alpine-Eval .ag-header-cell{
  padding-left:0px;
  border: 0.1rem solid white;
  padding-right:0px;
  text-decoration:none;
  box-sizing: border-box;
}

.ag-theme-alpine-Eval .ag-root-wrapper{
  border:none;
  text-decoration:none;
}
.ag-theme-alpine-Eval .ag-header{
  border:none;
  text-decoration:none;
}
.ag-theme-alpine-Eval .ag-cell{
  line-height: 36px;
  text-decoration:none;
}
.ag-theme-alpine-Eval .ag-ltr .ag-cell{
  border-radius:0.5rem;
  line-height: 3.6;
  text-overflow: ellipsis;
  font-family: sans-serif;
  border: 1px solid white;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.ag-theme-alpine-Eval .ag-ltr .ag-cell:nth-child(1){
  text-decoration:none;
  border-radius:0.5rem;
}
.ag-theme-alpine-Eval .ag-ltr .ag-cell:nth-child(2){
  border-radius:0.5rem;
  padding-left: 0.7rem;
  }

.ag-theme-alpine-Eval .ag-ltr .ag-cell:nth-child(3){
  text-decoration:none;
  border-radius:0.5rem;
  text-align: center;
  justify-content: center;
}

.ag-theme-alpine-Eval .ag-ltr .ag-cell:nth-child(4),
.ag-theme-alpine-Eval .ag-ltr .ag-cell:nth-child(5),
.ag-theme-alpine-Eval .ag-ltr .ag-cell:nth-child(7){
  border-radius:0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-theme-alpine-Eval .ag-ltr .ag-cell:nth-child(6){

  border-radius:0.5rem;
  text-decoration:none;
  justify-content: center;
  align-items: center;
  
}

.ag-theme-alpine-Eval .rowGreyBackgroundwithImgActive{
  background-color: #f6f6f6;
  text-align:'center';
  text-decoration:none;
  background-image: url('../../images/boy.svg');
  background-position: left 5% top 52%;
  background-repeat: no-repeat;
  background-size: 1.2rem;
  text-align:'left';
  text-decoration:none;
}

.ag-theme-alpine-Eval .rowGreyBackgroundwithImgInactive::before{
  content: '';
  position: absolute;
  background-image: url('../../images/boy.svg');
  background-position: left 5% top 52%;
  background-repeat: no-repeat;
  background-size: 1.2rem;
  width: 100%;
  height: 100%;
  filter: invert(50%);
}

.ag-theme-alpine-Eval .rowGreyBackgroundwithImgInactive{
  background-color: #f6f6f6;
  text-align:'center';
  text-decoration:none;
  text-align:'left';
  text-decoration:none;
}

.ag-theme-alpine-Eval .rowGreyBackgroundwithAgencyImg{
  background-image: url('../../images/car.svg');
  background-position: left 1rem top 50%;
  background-repeat: no-repeat;
  background-size: 1.2rem;
  background-color: #f6f6f6;
  text-align:'left';
  text-decoration:none;
}
.ag-theme-alpine-Eval .rowGreyBackground{
  background-color: #f6f6f6;
  text-align:'center';
  text-decoration:none;
}
.ag-theme-alpine-Eval .rowBlueBackground{
  background-color: #e6f3ff;
  text-align:'center';
  text-decoration:none;
}
.ag-theme-alpine-Eval .rowGreenBackground{
  background-color:#DFF7E6;
  text-align:'center';
  text-decoration:none;
}
.ag-theme-alpine-Eval .ag-input-field-input{
  height:2.7rem;
  width:100%;
}
.ag-theme-alpine-Eval .ag-ltr input[class^="ag-"][type="text"]{
  color:black;
  text-decoration: none;
  height: 30px;
  font-size: 14px;
  z-index: 1000;
  border: 1px solid hsl(0, 0%, 80%);
  padding: 0px 0px 0px 2.5rem;
  border-radius: 4px;
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: left 1rem bottom 50%;
  background-image: url('../../imagesMB/search.svg');
  margin: 0px 1px 0px 1px;
  cursor: pointer;
}

.ag-theme-alpine-Eval .ag-ltr input[class^="ag-"][type="text"]:focus-visible {
  outline-color: #2684FF;
}

.ag-theme-alpine-Eval .ag-ltr input[class^="ag-"][type="text"]::placeholder {
  color: #808080;
}

.ag-theme-alpine-Eval .ag-header-row:not(:first-child) .ag-header-cell{
  background:#eff0f6;
  border:none;
  height:43px;
}

.ag-theme-alpine-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(1){
  background:#eff0f6;
  border-radius: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ag-theme-alpine-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(2),
.ag-theme-alpine-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(3),
.ag-theme-alpine-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(4),
.ag-theme-alpine-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(5),
.ag-theme-alpine-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(6),
.ag-theme-alpine-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(8),
.ag-theme-alpine-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(10){
  background:#eff0f6;
  border-radius: 0px;
}

.ag-theme-alpine-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(7){
  background:#eff0f6;
  border-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ag-theme-alpine-Eval .ag-header-row:not(:first-child) .ag-header-cell:nth-child(9)
{
  background:#eff0f6;
  border-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ag-theme-alpine-Eval .ag-header-row .ag-header-cell{
  background:#999999;
  border-radius:5px;
  color:white;
  border:1px solid white;
}
.ag-theme-alpine-Eval .ag-header-row .ag-header-cell:nth-child(4),
.ag-theme-alpine-Eval .ag-header-row .ag-header-cell:nth-child(5){
  background:#53AD49;
  color:white;
}
.ag-theme-alpine-Eval .ag-header-row .ag-header-cell:nth-child(7){
  background:#999999;
  color:white;
  text-align: center;
  background:#297fcc;
  color:white;
}
.ag-theme-alpine-Eval .ag-header-row .ag-header-cell:nth-child(8),
.ag-theme-alpine-Eval .ag-header-row .ag-header-cell:nth-child(9){
  background:#297fcc;
  color:white;
}

.ag-theme-alpine-Eval .ag-row{
  border-width:0px;
  border-bottom-style: none;
  border: 0.1rem solid white;
  border-top: 0.2rem solid white;
}


.ag-theme-alpine-Eval a{
  text-decoration: none;
  color:black;
  font-size:1rem;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
}

.ag-theme-alpine-Eval .ag-ltr .ag-body-viewport{
  margin-top:-0.1rem;
}